import type { FC } from 'react';
import React, { Fragment } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import { N500 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

import type { ElementFactoryInterface } from './TableOfContents';
import { EmptyTocItem } from './TableOfContents';
import type { ElementsInterface, KeyableInterface } from './TableOfContentsElementStructure';
import { TocItemBody } from './TableOfContentsElementStructure';

function getInitialSeparator(ref: any) {
	if (ref.current === true) {
		ref.current = false;
		return true;
	} else {
		return false;
	}
}

interface TableOfContentInterface {
	elementsArray: ElementsInterface[] | null;
	elementFactory: ElementFactoryInterface;
	config: KeyableInterface;
	separators: string[];
	initialSeparatorRef: any;
	isInEditMode: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyContainer = styled.div({
	color: token('color.text', N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
});

const i18n = defineMessages({
	emptyToc: {
		id: 'table-of-contents.empty-toc',
		description: 'The message to display when there are no headings in the page',
		defaultMessage: 'Add headings to this page and they’ll instantly appear here.',
	},
	livePageEmptyToc: {
		id: 'table-of-contents.live-page-empty-toc',
		description: 'The message to display when there are no headings in the live page',
		defaultMessage: 'Add headings to this doc and they’ll instantly appear here.',
	},
});

/**
 *
 * @param param
 *
 * elementsArray: Filtered Heading elements found within the ADF
 * elementFactory: Gets list-style or flat-style templates
 * config: Options passed by the macroNode parameters
 * separators: For flat-style toc, separators include brackets, braces, pipe
 * initialSeparatorRef: Reference to keep track of whether or not the first separator has been casted
 *
 */
export const TableOfContentsComponent: FC<TableOfContentInterface> = ({
	elementsArray,
	elementFactory,
	config,
	separators,
	initialSeparatorRef,
	isInEditMode,
}) => {
	const intl = useIntl();
	const isLivePage = useIsCurrentPageLive();

	if (!elementsArray) {
		if (isInEditMode) {
			return (
				<EmptyContainer>
					{intl.formatMessage(
						isLivePage && fg('confluence_live_pages_open_beta_trait_opted_in')
							? i18n.livePageEmptyToc
							: i18n.emptyToc,
					)}
				</EmptyContainer>
			);
		} else {
			return <div />;
		}
	}

	const ElementsContainer = elementFactory.createTocLevelContainer();
	const ItemContainer = elementFactory.createTocItemContainer();

	if (config.structure === 'flat') {
		// Subtree helper
		function buildFlatSubtree(subArray: ElementsInterface[]) {
			return subArray.map((element) => {
				return (
					<Fragment key={element.outline}>
						{element.link !== EmptyTocItem ? (
							<Fragment>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className="toc-separator">
									{getInitialSeparator(initialSeparatorRef) ? separators?.[0] : separators?.[1]}
								</span>
								{createTocItemBodyComponent(element, config.outline)}
							</Fragment>
						) : null}

						{element.children ? buildFlatSubtree(element.children) : null}
					</Fragment>
				);
			});
		}
		return (
			<ElementsContainer prop={config}>
				{elementsArray.map((element, idx) => {
					return (
						<Fragment key={idx}>
							{element.link !== EmptyTocItem ? (
								<Fragment>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className="toc-separator">
										{getInitialSeparator(initialSeparatorRef) ? separators?.[0] : separators?.[1]}
									</span>
									{createTocItemBodyComponent(element, config.outline)}
								</Fragment>
							) : null}
							{element.children ? <Fragment>{buildFlatSubtree(element.children)}</Fragment> : null}
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className="toc-separator">
								{idx === elementsArray.length - 1 ? separators?.[2] : null}
							</span>
						</Fragment>
					);
				})}
			</ElementsContainer>
		);
	}

	// List-style TOC
	if (elementsArray) {
		return (
			<ElementsContainer prop={config}>
				{elementsArray.map((element) => {
					if (element.innerContent) {
						return (
							// there's no unique identifier for element
							// eslint-disable-next-line react/jsx-key
							<Fragment>
								{element.link !== EmptyTocItem || element?.children ? (
									<ItemContainer link={element.link} prop={config} key={element?.outline}>
										{element.link !== EmptyTocItem
											? createTocItemBodyComponent(element, config.outline)
											: null}
										{element.children ? (
											<TableOfContentsComponent
												elementsArray={element.children}
												elementFactory={elementFactory}
												config={config}
												separators={separators}
												initialSeparatorRef={initialSeparatorRef}
												isInEditMode={false}
											/>
										) : null}
									</ItemContainer>
								) : null}
							</Fragment>
						);
					} else {
						return null;
					}
				})}
			</ElementsContainer>
		);
	}

	return <div data-testid="Empty-TOC" />;
};

/**
 *
 * @param element
 * @param displayOutline
 *
 * Build the Inner item body for TOC
 *
 */
function createTocItemBodyComponent(element: ElementsInterface, displayOutline: string) {
	return TocItemBody(element, displayOutline);
}
